.Contact-Container h2{

    font-size: 24px;
    margin-bottom: 70px;

}

.pOne,.pTwo,.pThree{
    height: 30px;
    text-align: center;
    font-size: 20px;

}
.pOne{
    width: 458px;
    margin-left: 170px;
}
.pTwo{
    width: 519px;
    margin-left: 150px;
}
.pThree{
    width: 741px;
    margin-left: 62px;
}
.ContactUs{
    text-align: center;
}
.Contact-info{
    width: 800px;
    margin-top: 55px;
    margin-left: 275px;



}
.Contact-info span{
    font-weight: 600;
    margin-bottom: 15px;
    
}
.Contact-info div{
    display: inline-block;
    width: 25%;
    align-items: center;
    justify-content: center;
}
.ContactUs{
    text-align: center;
   
}
.p-wrapper{
    width: 800px;
    height: 200px;
    text-align: center;
    margin: auto;
}
.Address{
    margin-top: 60px;
}
.Address span{

    font-weight: 600;
    margin-bottom: 50px;
    font-size: 22px;
    
}
.article1 p{
    margin-bottom: 0;
}
.under{
    text-decoration: underline;
}
.FAQ{
    margin-top: 50px;

    
}

@media only screen and (max-width: 600px){

    .Contact-Container h2{
        margin-top: 20px;
        margin-bottom: 30px;
        font-size: 16px;
        padding-bottom: 0;
      
    }

    .Contact-Container{
    font-size: 14px;
   

    }


    .p-wrapper{
        width: 100%;
        text-align: center;
    }

    .Contact-info{
        display: flex;
        flex-direction: column;
        margin-left: 0;
        width: 100%;
        margin-top: 0;

    }
    .article1{
        width: 100%;
        margin: auto;
        margin-bottom: 1rem;
    
    
    }
    .article1 span{
        font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 18px;
color: #464343;
    }
    .under a{
        color: black;
    }
  
    .article1 p:nth-child(2){
        text-decoration: underline;
        font-size: 15px;
    }
    .article1 p{
        font-size: 15px;
     
    
    }
    .Address{
        width: 100%;
        padding:3%;
    }
    .Address span{

        font-size: 18px;
      
       
    }
    .Address a{
        color: black;
    }

    .Address p{

        padding-top: 8px;
        text-decoration: underline;
        font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 16px;
    }

    .p-wrapper{
        width: 90%;
    }

    .pOne{
        width: 100%;
        margin-left: 0;
        font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 21px;

    }
    .pTwo{
        width: 100%;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        margin-left: 0;


    }
    .pThree{
        width: 100%;
        height: 42px;
        font-size: 14px;
        line-height: 21px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        margin-left: 0;
    }

    .FAQ{

        width: 100%;
    
    }
    .Contact-info div {
        width: 160px;
    }
    .sp3{
        width: 230px;
        white-space: nowrap;
    }

}


