@media only screen and (max-width:768px) {

    .second-contact-contents{

        margin-left: 5%;
    }
    .ContainMb{

        margin-top: 5%;
        width: 100%;
        height: 100%;
        background-color: rgba(241, 241, 241, 1);
    }
.content-container{
    margin-top: 5%;
    width: 100%;
    height: 100%;
    margin: auto;
    background-color: rgba(241, 241, 241, 1);
    display: flex;
    
}

.contact-contents{
    width: 50%;
}

.contact-contents h2{
  
margin-top: 5%;
padding-bottom: 4%;
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
margin-left: 2.25rem;

color: #464343;
    
}
.footer-lists ul{
    padding-left: 0;
}
.contact-contents img{
    
    width: 0.938rem;
    height: 0.938rem;
    margin-right: 0.625rem;
}
.first-row,.second-row,.third-row{

    margin-left: 2.25rem;
    margin-bottom: 0.7rem;
    display: flex;
    align-items: center;
    word-spacing: normal;

}
.contact-contents span{

font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 14px;
width: 8.688rem;
text-align: justify;


color: #464343;
}


.second-contact-contents h2{
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
margin-top: 5%;
margin-left: 12%;

}
.second-contact-contents{
    width: 50%;
}

.footer-lists{

font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 14px;
}

.footer-lists ul{
    list-style: none;
}
.footer-lists li{
    padding-bottom: 4px;
}

.social-icons-mb{

    height: 1.5rem;
    margin: auto;
   
    margin-bottom: 1rem;
    margin-left: 0.8rem;
}
.image-nav{
    height: 1rem;
    margin-top: 1rem;
    
}
.footer-mb{
  
  
font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 14px;
margin-bottom: 1rem;
padding-bottom: 1rem;

}

.social-icons-contain{
   text-align: center;
}
}