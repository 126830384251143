@media(max-width: 600px) {
    h1 {
        padding-bottom: 40px;
        margin-top: 30px;
    }
    .sllAr h1{
        padding-top: 1rem;
    }
    .sllAr{
        height: auto;
        width: 100%;
    }
   
}

.SS .slick-arrow.slick-prev {
    
    border: none;
    margin-left: 2.813rem;
    font-size: 12px;
    font-weight: normal;
}

.SS .slick-arrow.slick-next {
    border: none;
    margin-right: 2.5rem;
    font-size: 12px;
}
.sc-kgflAQ {
    width: 200px;
}
