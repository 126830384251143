    @import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
    table {
        border-collapse: separate;
        border-spacing: 30px;
    }
    table td {
      padding: 20px;
  }
    td {
        width: 275px;
        text-align: center;
        padding: 25px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
    }
    
    .White{
        width: 150px;
        height: 40px;
        background: #747171;
        border-radius: 20px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        color: #fff !important;
        padding: 10px 28px;
    }
    table{
      margin: auto;
    }
    
    .space {
        /* margin-left: 240px; */
        max-width: 1200px;
        margin: auto;
    }
    .mobile-white1{
      display: none;
    }
    .mobile-white2{
      display: none;
    }
    .book{
      width: 96vw !important;
  }
  .White{
    cursor: pointer;
  }
    
  
    @media (max-width: 768px) {
        .space {
            margin: 0;
            padding: 0;
            max-width: 100vw;
        }
        table {
            border-spacing: 0;
            
        }
        .book{
          width: 100vw !important;
        }
        
        td {
            
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
padding: 16px;

/* identical to box height */


color: #464343;
            white-space: nowrap;
        }
        table td{

          padding-left: 0;
padding-bottom: 5px;

        }
        .White {
            font-size: 10px;
            padding: 2px 8px;
            display: none;
        }
        .mobile-white1{
        
          display: block;
          width: 33px;
          height: 33px;
          margin: auto;
          margin-right: 2px;
  
        }
        .mobile-white2{
        
            display: block;

            width: 33px;
            height: 33px;
          margin: auto;
        }
        
        h5 {
            font-size: 16px !important;
        }
        /* .mainDiiv {
            margin-top: 60px;
        } */
        /* .space2 {
            margin-left: 240px;
        } */
        .toastwrap .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
            margin-left: -590px;
            transform: rotate(270deg);
        }
        .MuiAccordionSummary-expandIconWrapper.Mui-expanded.css-yw020d-MuiAccordionSummary-expandIconWrapper {
            transform: rotate(360deg);
             
            
        }
        .MuiAccordionSummary-content.Mui-expanded.MuiAccordionSummary-contentGutters.css-o4b71y-MuiAccordionSummary-content {
            border-bottom: 1px solid;
            padding-bottom: 7px;
        }
        .type{
          margin-left: 1rem;
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          
          /* identical to box height */
          
          
          color: #464343;
        }
     
        .changed{
          padding-left: 0;
        }
        
    }
   

     