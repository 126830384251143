.contain {
    width: 900px;
    margin: auto;
}
.map{
    width:1000px;
    height: 600px;
    border: 0;
    
    
     
    
}
.map-contain{
    text-align: center;
    margin: 40px;
}


h1 {
    text-align: center;
    padding-bottom: 69px;
    padding-top: 32px;
}
.mb-map{
    display: none;
}

@media screen and (max-width: 750px) {
    .map{
        display: none;

    }
    .mb-map{
        display: block;
        width: 90%;
        text-align: center;
        margin: auto;
    }
    .mb-map .map-mb{
        width: 100%;
    }
    .contain {
        width: 420px;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100vw;
    }
    .contain>h1 {
        font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 27px;
    color: #464343;
        padding: 0;
        margin-top: 16px;
    }
    h1{
        width: 100vw;
        font-size: 20px;
    }
}