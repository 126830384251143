.Popup {
    background-color: rgba(255, 255, 255, 1);
    width: 1000px;
    padding: 30px 40px;
    /* position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%; */
    border: 2px solid black;
    position: relative;
    margin: auto;
}


#close {
    display: block;
    margin: -80px 0 0px auto;
    background-color: transparent;
    font-size: 30px;
    color: #000;
    border: none;
    outline: none;
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 90px;
}

.Popup-Container {
    height: 100vh;
    width: 100vw;
    position: relative;
}

.block {
    display: flex;
    align-items: center;
    margin: 0 20px;
    margin-top: 30px;
}

.form-wrapper {
    display: flex;
}

.form-One img {
    width: 235px;
    height: 255px;
    margin-right: 27px;
    margin-top: 15px;
}

.Popup h3 {
    font-size: 22px;
    text-align: center;
    margin-bottom: 45px;
   
}

.Popup h4 {
    font-size: 18px;
    text-align: center;
    margin-bottom: 25px;
}

.event,
.artist,
#start {
    width: 136px;
    height: 37px;
    border: 1px solid rgba(116, 113, 113, 1);
    margin-left: 70px;
    margin-right: 15px;
    margin-bottom: 51px;
    margin-top: 15px;
}

#start {
    margin-left: 30px;
}

.location {
    width: 561px;
    height: 50px;
}

.form-One h4 {
    text-align: center;
    font-size: 20px;
}

.Outside .span1 {
    margin-left: 40px;
    padding-top: 20px;
    width: 236px;
    height: 30px;
    font-size: 20px;
    font-weight: 500;
}

.span2 {
    display: block;
    width: 222px;
    height: 30px;
    font-size: 10px;
    color: rgba(70, 67, 67, 1);
    margin: auto;
}

.span3 {
    display: block;
    width: 222px;
    height: 30px;
    font-size: 10px;
    color: rgba(70, 67, 67, 1);
    margin: auto;
}

.radio1,
.radio2 {
    margin-left: 48px;
    margin-right: 14px;
    color: rgba(70, 67, 67, 1);
    margin-top: 20px;
}

.radio2 {
    margin-left: 300px;
}

.btn-lg {
    text-align: center;
    margin-left: 300px;
    width: 330px;
    color: rgba(255, 255, 255, 1);
    font-size: 16px;
    background-color: rgba(116, 113, 113, 1);
    outline: none;
    border: none;
    margin-top: 26px;
}

.form-Two {
    max-width: 600px;
}
.Msing input[type="time"] {

    width: 136px;
    height: 37px;

}
.Msing {
    flex: 1;
    padding-right: 20px;
    justify-content: space-between;
    display: flex;
    align-items: end;
}

input,
select,
label {
    flex: 1;
}

label {
    font-size: 15px;
    color: grey;
}

input,
select {
    margin: 0 !important;
    margin-left: 15px !important;
    padding: 0 10px;
    border: 1px solid #202020;
}


/* input {
    appearance: none;
} */

.location {
    display: inherit;
    margin: 0 20px;
    margin-top: 35px;
}

.location select {
    padding: 0 4px;
    width: 100%;
    margin: 0 6px !important;
}

.location label {
    margin-bottom: 15px;
}

.radioCont {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: baseline;
}

input[type='radio'] {
    accent-color: #232323;
}

.Msingg>label {
    margin-left: 10px;
}

.bgH4 {
    display: initial
}

.smH4 {
    display: none
}

form table input[type='radio'] {
    box-sizing: border-box;
    appearance: none;
    background: white;
    outline: 2px solid #333;
    border: 3px solid white;
    width: 16px;
    height: 16px;
}

form table input[type='radio']:checked {
    background: #333;
}

table {
    font-size: 14px;
    color: rgba(70, 67, 67, 1);
    ;
}

table label {
    display: block;
}

table th {
    white-space: nowrap
}


.block1 label {
    max-width: 50px !important;
}

.block1 {
    margin-bottom: 50px;
}

.Popup {
    overflow: scroll;
    height: 90vh;
    margin-top: 0;
    top: 5%;
}

.blablabla2 {
    background: transparent;
}

@media (max-width: 768px) {
    table td label {
        font-size: 12px;
    }
  
    .Popup h3 {
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .form-wrapper {
        display: initial;
    }
    .Popup {
        max-width: 94vw;
        margin: auto;
        padding: 5px 10px;
        /* margin-top: 190px; */
        z-index: 9999;
        overflow: scroll;
        height: 93vh;
        margin-top: 0;
        top: 5%;
    }
    .Popup h3 {
        margin-top: 64px;
        margin-bottom: 32px;
    }
    .form-Two {
        /* max-width: 50vw; */
        margin: auto;
    }
    .form-One {
        float: left;
        height: fit-content;
    }
    .form-One img {
        width: 180px;
        height: 196px;
        margin-right: 15px;
        max-width: 26vw;
        max-height: 33vw;
        min-width: 125px;
        min-height: 148px;
        margin-top: 65px;
    }
    .block {
        margin: 0px;
        margin-left: 0px;
        margin-top: 30px;
    }
    .event,.Msing{
        color: black;
       
    }
    .block1 {
        display: flex;
        flex-direction: column;
        margin-top: 0px;
        align-items: baseline;
        margin-bottom: 50px;
    }
    .block1 .Msing {
        /* margin-bottom: 25px; */
        margin-top: 25px;
        max-width: 45vw;
        position: relative;
    }
    .block2 {
        justify-content: space-between;
        margin: 0 10px;
    }
    .block2 .Msing {
        flex-direction: row;
        flex-direction: column;
        align-items: baseline;
        width: fit-content;
        flex: -1;
    }
    .Msing {
        margin-bottom: 0px;
        padding-right: 0px;
    }
    /* label {
        font-size: 15px;
    } */
    .block2 .Msing input {
        padding: 10px;
        /* width: 90%; */
    }
    input,
    select {
        margin: 0 !important;
        margin-left: 0px !important;
        padding: 0 5px;
        font-size: 14px !important;
    }
    .bgH4 {
        display: none
    }
    table label{
        display: inline-block;
    }
    table td label{
        vertical-align: -webkit-baseline-middle;
        margin-left:7px ;
        font-size: 11px;
        
    }
    table td{
       
        padding-top: 18px;
        padding-left: 6px;
        padding-bottom: 5px;
    }
    .smH4 {
        display: initial;
        font-size: 16px;
        margin-left: -30px;
       
    }
    label {
        font-size: 16px;
    }
    .location {
        margin-left: 4px;
        height: auto;
        width: 75vw;
    }
    .location select {
        margin-left: 0;
        height: 50px;
    }
    .Outside {
        margin-top: 20px;
        margin-left: 11px;
    }
    .span1 {
        height: auto !important;
        margin-bottom: 20px;
    }
    .Msingg {
        margin-bottom: 16px;
    }
    .btn-lg {
        margin: auto;
        width: 250px;
        font-size: 15px;
        margin-bottom: 26px;
        display: block;
    }
    .sing img{
        width: 17px;
        height: 17px;
    }
    .table td{
        padding: 0;
       
    }

}

.infoss {
    margin-left: 10px;
}

.infoss .sing {
    display: flex;
    align-items: center;
    margin-top: 15px !important;
}

.infoss .sing a {
    text-decoration: none;
    color: grey;
    margin-left: 15px;
}

.infoss img{
    
        width: 17px;
        height: 17px;
    
}


@media (max-width:450px) {
    /* label {
        font-size: 13px;
    } */
    /* input,
    select {
        font-size: 12px !important;
    } */

}   