.slick-track {
    display: flex;
}

.slick-slide {
    width: 50px;
}

.img-slide {
    margin-left: 10px;
}
.img-slide h2{
    text-align: center;

}

.img-slider-contain .slick-slide {
    width: auto !important;
}

.slick-prev:before {
    content: none !important;
}

.img-slider-contain {
    margin-left: 60px;
}

.sliderContainer {
    width: 500px;
    margin-left: 70px;
}

.firstimgSS img {
    width: 500px;
    height: 562px;
}

.slick-arrow.slick-prev {
    left: -12px;
    z-index: 1;
}

.slick-arrow.slick-next {
    right: -13px;
}

.img-slider-contain .slick-current {
    border: 2px solid red;
    padding-right: 2px;
}


/* .img-slide {
    width: 95%;
} */

.img-slider-contain {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 500px;
    margin-top: 40px;
}

.img-slider-contain h2 {
    font-size: 18px;
}

.img-slide#active {
    width: 26% !important;
    position: relative;
    margin: 0;
}

.img-slide#active>img {
    padding: 6%;
    /* border: 1px solid black; */
}

.img-slider-contain img {
    margin-top: 4px;
    margin-right: 4px;
    /* width: 85%; */
}

.slick-arrow.slick-next {
    height: 20px;
    width: 10px;
    border: 2px solid black;
    border-radius: 10px;
    padding: 20px;
    position: absolute;
    top: 50%;
    right: -1em;
    font-size: 20px;
}

.slick-arrow.slick-next {
    background-repeat: no-repeat;
    background-position: center;
    background-color: white;
    background-image: url("../../../public/Images/Right.png");
    height: 16px;
    width: 16px;
    border: 1px solid black;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 13px;
    position: absolute;
    top: 50%;
    right: -12px;
    font-size: 20px;
    background-size: 9px;
}

.slick-arrow.slick-prev {
    background-repeat: no-repeat;
    background-position: center;
    background-color: white;
    background-image: url('../../../public/Images/Left.png');
    height: 16px;
    width: 16px;
    border: 1px solid black;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 13px;
    position: absolute;
    top: 50%;
    left: -12px;
    font-size: 20px;
    background-size: 9px;
}

.slick-arrow.slick-next::before,
.slick-arrow.slick-prev::before {
    display: none !important;
}


/* .reArrow .slick-arrow::before,
.reArrow .slick-arrow::after {
    display: none;
} */


/* .slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
    pointer-events: none;
} */

.img-slider-contain .slick-current {
    border: 2px solid black;
}

.sllAr .slick-arrow.slick-next {
    right: 12px !important;
}

.sllAr .slick-arrow.slick-prev {
    left: 12px !important;
}

@media(max-width: 766px) {
    .slick-arrow {
        display: flex !important;
    }
}

@media(max-width:600px) {
    .sliderContainer {
        margin: auto;
        max-width: 100vw;
    }
    .img-slide h2 {
        font-size: 12px;
        margin: 0;
        text-align: center;
        padding-top: 8px;
    }
    .sliderContainer .slick-dots{
        bottom:18px;
    }
    .sliderContainer .slick-dots li button:before{
        font-size: 9px;
        opacity: 1;
        color: white;
    }
    .same1{
       transition: transform 450ms;
    }
    .same1:hover{
        transform:scale(1.08);
    }
    
    /* .slick-slider {
        transform: translateX(10%);
        border-radius: 15px;
        overflow: hidden;
    } */
    .sllAr .slick-arrow {
        display: initial !important;
        background: none !important;
        border: none !important;
        margin: 0 !important;
        padding: 0 !important;
    }
    .sllAr .slick-arrow.slick-next::before {
        right: 2px;
        content: ">";
        top: 30%;
        color: #585858 !important;
        font-size: 26px !important;
        font-weight: bolder;
        font-family: monospace;
        position: absolute;
    }
    .sllAr .slick-arrow.slick-prev::after {
        left: 2px;
        top: 70%;
        content: "<";
        color: black;
        font-size: 32px;
        font-weight: bolder;
        font-family: monospace;
        position: absolute;
    }
    .sllAr2 .slick-arrow {
        display: none !important;
    }
    .SliderSyncwrapper {
        margin-top: 30px !important;
    }
    .slick-arrow.slick-prev {
        left: 0em;
    }
    .slick-arrow.slick-next {
        right: 0em;
    }
    .img-slide {
        width: 24% !important;
        position: relative;
        margin: 0;
    }
    .sliderContainer .slick-slide img{
        height: 75vh !important;
        object-fit: cover;
    }
    /* .img-slide#active {
        width: 26% !important;
        position: relative;
        margin: 0;
    }
    .img-slide#active>img {
        padding: 6%;
        border: 1px solid black;
    } */
    /* .img-slider-contain>.slick-initialized .slick-slide {
        width: 25% !important;
    } */
    .img-slider-contain>.slick-initialized .slick-slide div {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .img-slide>img {
        width: 100%;
        margin: 0;
        padding: 6px;
    }
    .img-slider-contain {
        max-width: 100vw;
        margin: 0;
       
    }
    .img-slider-contain .slick-current {
        border: 2px solid black;
        padding-right: 2px;
        padding: 7px 0;
    }
    .slick-arrow.slick-next {
        right: 2px;
    }
    .slick-arrow.slick-prev {
        left: 2px;
    }
    /* .slick-slide .firstimgSS img {
        width: 95% !important;
        margin-right: 2.5%;
        margin-left: 2.5%;
        transform: translateX(10%);
        transform: translateX(31.5%);
    } */

    .firstimgSS{
        padding: 0 15px;
    }
}