.Media-Gallery div:first-child{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

}
.img-mb-style{
    object-fit: cover;
}
.bridal{
    width: 100%;

 
}
.Media-Gallery{
    margin-left: 0px !important;
}
