@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
.Container{

    height: 100vh;
    width: 100vw;
}
.aboutmb{
    display: none;
}
.About-Section{
    display: flex;
    margin-bottom: 43px;

}
.Container p{
    text-align: justify;
}
.First img{

    width:500px;
    height: 578px;
    margin-left:121px;
}
.Second p{
    width: 673px;
    font-size: 18px;
    margin-left: 50px;
    font-family: 'Montserrat';
font-style: normal;
font-weight: 500;
}
.Container h2{
    font-size: 28px;
    text-align: center;
    margin-top: 56px;
    margin-bottom: 70px;
}
.First p{

    margin-left: 50px;
    
}


.outer{
    font-size: 18px;
    margin-left: 105px;
    width: 1200px;
    margin-bottom: 90px;
}
.outer p{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 400;

}
.Last{
    margin-left: 110px;
    margin-bottom: 90px;
    width: 90%;
}
.Last h3{
    margin-bottom: 2.125rem;
    font-size: 1.5rem;
}
.Last p{
    font-size: 18px;
    text-align: justify;
    word-wrap: break-word;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    
}
.Last p:last-child{
    margin:20px 0;

}
.show{
    display: none;
}
.mobile-about-section{
    display: none;
}

@media only screen and (max-width:768px) {

    .Container{
        display: none;
    }

   
    .aboutmb{
        display: block;
    }
   
    

}
