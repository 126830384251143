@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');
.wrapper{
    height:100vh;
    width:100vw;

    


  
}


.Service-Container p{
    margin-left: 146px;
    margin-right: 125px;
    width:1169px;
    font-size: 18px;
line-height: 22px;
text-align: center;

font-family: 'Montserrat';
font-style: normal;
font-weight: 400;
}
.SCP1{
    margin-top: 7.188rem;
}
.Service-Container p:last-child{
    margin-top: 20px;
    margin-top: 60px;
}
.h2{
    margin-bottom: 25px;
    text-align: center;
}

.button a{

    border:2px solid black;
    padding: 10px;
    border-radius: 10px;
    font-size: 18px;
    margin-left: 113px;
    font-family: 'Poppins';
font-style: normal;
font-weight: 500;




}
.button a:last-child{
    padding: 8px 4rem;
}
.button a:hover{
    background: #747171;
border-radius: 10px;
color: #ffffff !important;
cursor: pointer;
position: relative;

}

.button a:hover ::after{
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    margin-left: -0.5em;
    bottom: -2em;
    left: 50%;
    box-sizing: border-box;
    
    border: 1em solid black;
    border-color: transparent transparent #bada55 #bada55;
    
    transform-origin: 0 0;
    transform: rotate(-45deg);
    
    box-shadow: -3px 3px 3px 0 rgba(0, 0, 0, 0.4);
  }
.wrapper .button{
    text-align: center;
    margin-top: 160px;
    margin-bottom: 30px;
    margin-left: -40px;

}
.Box{
    display: flex;
    justify-content: center;
    align-items: center;

}
.One img{
    width: 300px;
    height:443px;
    margin-right: 52px;
    margin-left: 39px;
    margin-bottom: 65px;
    
    
}
.Box-Container{

    width: 1144px;
    height: 660px;
    border:2.5px solid black;
   
    margin: auto;
    margin-top: 58px;
    margin-bottom: 40px;

}
.Box-Container h2{
    font-size: 24px;
    padding: 30px;
    text-align: center;
}

.Box-Container p{
    width: 673px;
    font-size: 18px;
}
.Two article{
    
    border-radius: 20px;
    width: 150px;
    height: 36px;
    font-size: 18px;
    color: #fff;
    background-color: rgba(116, 113, 113, 1);
    text-align: center;
    margin: auto;
    margin-top: 30px;
    padding-top: 4px;
    padding-left: 2px;

}


.Two{
    margin-bottom: 25px;
}

.Service_mb{
    display: none;
}

@media only screen and (max-width: 600px){

    .wrapper{
        display:none;
    }
    .Service_mb{
        display: block;
    }

    .Box-Container .mobile-hair1,.Box-Container .mobile-hair2{
        display: block;
        width: 100%;;
        margin-left: 0;
        margin-right: 0;
        font-family: 'Montserrat';
font-style: normal;
font-weight: 400;
font-size: 10px;


    }

    .One{
        width: 50%;
    }
    .Two{
        width:50%;
        align-items: center;
    }

    .wrapper .button{
        margin-top: 2em;
    }
    .button{ 
       width: auto;
       height: auto;
        background: #FFFFFF;
        border-radius: 20px;
          margin-left: 0;
          padding: 0;
          

    
    
    }
    .button a{
        height: 1.5em;
        width:5.6em;
        margin-left: 0;
       padding: 5px;
       font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 10px;
line-height: 15px;
margin-top: 0;
margin-left: 1em;


/* identical to box height */

color: #464343;

    }

    .Box-Container{

        width: 22em;
        height: 31.25em;
        background: #FFFFFF;
border: 1.5px solid #909090;
box-sizing: border-box;

    } 

    .Box-Container h2{
        font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 21px;

/* identical to box height */

color: #464343;
    }

    .One img{
        width: 8.75em;
        height: 12.923em;
        margin-left: 0;
        margin-top: 0;
        justify-content: center;
        align-items: center;
        margin-bottom: 0;
        margin-left: 10px;

    }
   
    .Two p:first-child,.Two p:nth-child(2),.Two p:nth-child(3),.Two  p:nth-child(4){

        display: none;
    }
    .wrapper{
        text-align: center;
        max-width: 100vw;
    }

    .Service-Container p{
        width:22.6em;
        font-size: 0.625em;
        margin: auto;
  
        
font-family: 'Montserrat';
font-style: normal;
font-weight: 400;
font-size: 10px;
line-height: 12px;
text-align: center;

    }

    .Service-Container p:last-child{
        margin-top: 10px;
    }

    .mobile-outer1{
    
        width: 331px;
      

font-family: 'Montserrat';
font-style: normal;
font-weight: 400;
line-height: 12px;
display: block;
font-size: 10px;
}


.mobile-outer2{
    display: block;
    width: 331px;
        font-size: 10px;

font-family: 'Montserrat';
font-style: normal;
font-weight: 400;
}
    
}