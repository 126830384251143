.sliderWrap {
    width: 100vw;
}

.sliderGallery img {
    width: 274.27px;
    height: 353.05px;
}

.space {
    width: 1142px;
}

.sliderGallery img:first-child {
    margin-left: 50px;
}


/* .sliderWrap .slick-prev {
    margin-left: 50px;
    background-color: grey;
}

.sliderWrap .slick-next {
    margin-right: 36px;
    background-color: grey;
    content: ' ';
} */

.sliderWrap .slick-next:before {
    display: none;
}

.checking .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
    margin-left: -495px;
    transform: rotate(270deg);
}

.checking .css-o4b71y-MuiAccordionSummary-content.Mui-expanded {
    border-bottom: 1px solid #909090;
    padding-bottom: 7px;
}

@media (max-width: 768px) {
    .slick-slide img {
        width: 100%;
        height: auto;
    }
    /* .slick-arrow {
        display: none !important;
    } */
    /* .sliderWrapAr>.slick-arrow {
        display: initial !important;
        background: none !important;
        border: none;
    } */
    .sliderGallery img:first-child {
        margin-left: 0px;
    }
}