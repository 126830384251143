@media only screen and (max-width:375px) {
    
    .services-h1 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 1.125rem;
        line-height: 1.6875rem;
        margin:0 auto;
        height: 1.5rem;
        width: 7.063;
        padding-top: 0;
        margin-top: 2.5rem;
      }
      .Service-Container-mb {
        padding: 0;
        /* width: 90vw; */
        width: 90%;
        margin: 0;
        display: flex;
        flex-direction: column;
        margin: auto;
        /* justify-content: space-between; */
        align-items: center;
        /* text-align: justify; */
    }
      .text-wrap-mb{
          font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 0.600rem;
      line-height: 0.75rem;
      text-align: justify;

  
      height: auto;
      padding:5%;
      font-size: 13px;
    line-height: 17px;
    text-align-last: center;
      
      }
      .text-wrap-mb2{
        padding: 0 !important;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 0.600rem;
        line-height: 0.75rem;
        text-align: justify;
        height: auto;
        padding:5%;
        font-size: 13px;
      line-height: 17px;
      text-align-last: center;
      
      }
      .s1,.s2,.s3,.s4{
          width: 6 rem;
          background: #FFFFFF;
          height: auto;
      border: 1px solid #747171;
      box-sizing: border-box;
      border-radius: 1.25rem;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 0.625rem;
      line-height: 0.9375rem;
      margin-right:0.438rem;
      padding:0.313rem;
      }
      .s4{
        padding: 5px 17px;
      }
      .services-btn-mb{
          width: 100%;
    
          text-align: center;
      }
      
      .Service-Container-mb{
          width: 100%;
          height: 100%;
      
      }

      .s1:hover,.s2:hover,.s3:hover,.s4:hover{
        width: 9.375rem;
        height: 2.625rem;
        background: #747171;
        border-radius: 0.625rem;
        color: white;
        cursor: pointer;
       
      }

      .hair-box{
        width: 22.625rem;
        height: 32.25rem;
        margin: auto;
        margin-top: 1.25rem;
        background: #FFFFFF;
        border: 1.5px solid #909090;
        box-sizing: border-box;
      }


      .hair-box {
        width: 99vw;
        margin: 2rem auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

      .hair-box-image{
          width: 50%;
          flex:1;
          margin-left: 4%;
          margin-top: 0;
      }
      .hair-box-para{
          width: 50%;
          flex:1;
          

      }

      .hair-box-para p{

      font-size: 10px;
          line-height: 14px;
          text-align: justify;
      }

      .mobile-hair1,.mobile-hair2,.outer-mb{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;

      }
      .mobile-hair1,.mobile-hair2{

          margin-right: 1rem;
          width: 11rem;
          margin-left: 0;
          margin-top: 1rem;
      }
      .mobile-hair1{
        margin-top: 1rem;
      }


      .btn-view{
        width: 6.25rem;
        height: 1.438rem;
        
        background: #747171;
        border-radius: 20px;
        margin: auto;
     
        color: #ffffff;

font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 10px;
text-align: center;
padding-top: 4px;

      }

      .outer-mb{
          width: 20.688rem;
          margin-right: 1rem;
          margin-left:1rem;

      }
      .outer-mb p{
        font-size: 10px;
        line-height: 14px;
        text-align: justify;
        margin-top: 1rem;
        margin-bottom: 0;
      }
      .outer-mb p:last-child{
         margin-bottom: 1rem;
      }
      .hair-h2{
          margin-top: 1.063rem;
          font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 0.875rem;
line-height: 1.3125rem;
margin-bottom: 2.375rem;
text-align: center;
      }

      .img-hair{
        width: 140px;
        height: 219.76px;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        border-radius: 10px;
        margin-left: 5%;
      }
      .pureflex{
          display: flex;
          justify-content: center;
          align-items: center;
          height: 13.438rem;
          width: 100%;

      }
      .hair-box-image{
          flex: 1;
          width: 50%;
      }
      .hair-box-para{
          flex: 1;
          width: 50%;
      }


      
      
}

@media only screen and (min-width:375px) {
    
    .services-h1 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 1.125rem;
        line-height: 1.6875rem;
        margin:0 auto;
        height: 1.5rem;
        width: 7.063;
        padding-top: 0;
        margin-top: 2.5rem;
      }
      .Service-Container-mb {
        padding: 0;
        /* width: 90vw; */
        width: 90%;
        margin: 0;
        display: flex;
        flex-direction: column;
        margin: auto;
        /* justify-content: space-between; */
        align-items: center;
        /* text-align: justify; */
    }
      .text-wrap-mb{
          font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 0.600rem;
      line-height: 0.75rem;
      text-align: justify;

  
      height: auto;
      padding:5%;
      font-size: 13px;
    line-height: 17px;
    text-align-last: center;
      
      }
      .text-wrap-mb2{
        padding: 0 !important;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 0.600rem;
        line-height: 0.75rem;
        text-align: justify;
        height: auto;
        padding:5%;
        font-size: 13px;
      line-height: 17px;
      text-align-last: center;
      
      }
      .s1,.s2,.s3,.s4{
          width: 6 rem;
          background: #FFFFFF;
          height: auto;
      border: 1px solid #747171;
      box-sizing: border-box;
      border-radius: 1.25rem;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 0.625rem;
      line-height: 0.9375rem;
      margin-right:0.438rem;
      padding:0.313rem;
      }
      .s4{
        padding: 5px 17px;
      }
      .services-btn-mb{
          width: 100%;
    
          text-align: center;
      }
      
      .Service-Container-mb{
          width: 100%;
          height: 100%;
      
      }

      .s1:hover,.s2:hover,.s3:hover,.s4:hover{
        width: 9.375rem;
        height: 2.625rem;
        background: #747171;
        border-radius: 0.625rem;
        color: white;
        cursor: pointer;
       
      }
      .active1{
        background: #747171;
        color: white;
      }

      .hair-box{
        width: 22.625rem;
        height: 32.25rem;
        margin: auto;
        margin-top: 1.25rem;
        background: #FFFFFF;
        border: 1.5px solid #909090;
        box-sizing: border-box;
      }


      .hair-box {
        width: auto;
        margin: 2rem auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

      .hair-box-image{
          width: 50%;
          flex:1;
          margin-left: 4%;
          margin-top: 0;
      }
      .hair-box-para{
          width: 50%;
          flex:1;
          

      }

      .hair-box-para p{

      font-size: 10px;
          line-height: 14px;
          text-align: justify;
      }

      .mobile-hair1,.mobile-hair2,.outer-mb{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;

      }
      .mobile-hair1,.mobile-hair2{

          margin-right: 1rem;
          width: 11rem;
          margin-left: 0;
          margin-top: 1rem;
      }
      .mobile-hair1{
        margin-top: 1rem;
      }


      .btn-view{
        margin-bottom: 1rem !important;
        width: 6.25rem;
        height: 1.438rem;
        
        background: #747171;
        border-radius: 20px;
        margin: auto;
     
        color: #ffffff;

font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 10px;
text-align: center;
padding-top: 4px;

      }

      .outer-mb{
          width: 20.688rem;
          margin-right: 1rem;
          margin-left:1rem;

      }
      .outer-mb p{
        font-size: 10px;
        line-height: 14px;
        text-align: justify;
        margin-top: 1rem;
        margin-bottom: 0;
      }
      .outer-mb p:last-child{
         margin-bottom: 1rem;
      }
      .hair-h2{
          margin-top: 1.063rem;
          font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 0.875rem;
line-height: 1.3125rem;
margin-bottom: 2.375rem;
text-align: center;
      }

      .img-hair{
        width: 140px;
        height: 219.76px;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        border-radius: 10px;
        margin-left: 5%;
      }
      .pureflex{
          display: flex;
          justify-content: center;
          align-items: center;
          height: 13.438rem;
          width: 100%;

      }
      .hair-box-image{
          flex: 1;
          width: 50%;
      }
      .hair-box-para{
          flex: 1;
          width: 50%;
      }


      
      
}

