body.active-modal {
  overflow-y: hidden;
  overflow: hidden;
}

.btn-modal {
  padding: 10px 20px;
  display: block;
  margin: 100px auto 0;
  font-size: 18px;
}

.overlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 999;
}

.name {
  margin-right: 20px;
}
.overlay {
  background: rgba(49, 49, 49, 0.8);
  justify-content: center;
  text-align: center;
}

.modal-content {
  position: fixed !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  background-image: url("FormBackground.png") !important;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 20px 23px 20px 0px;
  border-radius: 3px;
  height: 470px;
  width: 670px !important;
  z-index: 99999999;
  display: flex;
  flex-direction: row !important;
  justify-content: center;
  align-items: center;
  border: 4px solid gray !important;
}

.modal-image {
  width: 320px;
  height: 360px;
  object-fit: contain;
}

.border {
  border: 5px;
  border-color: black;
  border-radius: 20px;
  border-width: 10px;
}

.close-modal {
  position: absolute;
  top: 1px;
  right: -1px;
  cursor: pointer;
  height: 30px;
  width: 30px;
}
.cross {
  padding: 5px 7px;
}

.enquire {
  margin: 0;
  color: white;
}
.wanttoknow {
  margin: 3px;
  color: white;
}
.from {
  display: flex;
  margin-top: 20px;
  text-align: center;
}

.submit {
  padding: 4px 20px;
  background-color: gray;
  color: white;
  border-radius: 10px;
  border: none;
  margin-top: 25px;
}

.inputs {
  border-bottom: 2px solid gray;
}
.nameinput {
  border: none;
  outline: none;
  margin-right: 10px;
}

@media (max-width: 768px) {
  .modal-content {
    background-image: url("FormBackgroundSmall.png") !important;
    flex-direction: column !important; /* Change to column on small screens */
    max-width: 90%;
    min-width: 330px;
    height: 85%;
    padding: 22px;
  }

  .modal-image {
    height: 440px;
  }

  .enquire {
    margin: 44px 0px 0px 0px;
    color: black;
  }

  .wanttoknow {
    color: black;
  }
}
@media (max-width: 440px) {
  .modal-content {
    background-image: url("FormBackgroundSmall.png") !important;
    flex-direction: column !important; /* Change to column on small screens */
    max-width: 90%;
    min-width: 330px;
    height: 80%;
    padding: 22px;
    background: "#f1f1f1";
    opacity: 70%;
  }

  .enquire {
    margin: 20px 0px 0px 0px;
    color: black;
  }

  .wanttoknow {
    color: black;
  }
  
}

@media (max-width: 380px) {
  .modal-content {
    height: 90%;
  }
}
