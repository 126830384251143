@media only screen and (max-width:768px) {
  
.about-box{
    height: auto;
    width: auto;
}

.flex-mb{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}    

.Last-about h3{

font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 30px;
}
.About-para {
    flex: 1;
    width: 50%;
    margin-top: 20px;
    margin-right: 4%;
   
}
.About-para p{
    font-size: 13px;
    line-height: 16px;
}
.about-image{
    flex: 1;
    width: 50%;
    margin-left: 5%;

   
   
}

.about-image img{
    width: 10.375rem;
    height: 13.813rem;
   

}
p{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 500;



}
.about-h2{
    font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 1.125rem;
text-align: center;
margin-top: 1rem;
}


.hidden-about{
 display: none; 
}
.outer-about-mb{
    margin-left: 3%;
    margin-right: 3%;
}
.outer-about-mb p{
    font-size: 13px;
    line-height: 17px;
}
.Readmore h2{
    font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 21px;
text-align: center;
margin-bottom: 0;
}
.Readmore{
    text-align: center;
}
.about-box p{
    text-align: justify;
}

.img-about{
    margin-right: 1rem;
}
}