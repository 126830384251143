.slide2,.slide3{

    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
    display: none;

}

@media (max-width:600px){
    .p1{
        padding: 1rem;
    }
}
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');